.container {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.titleDiv {
  /*height: 15vh;*/
  padding: 25px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.chessBoardTitle {
  color: white;
  font-size: 8vh;
  margin: 0 auto;
}

.chessBoardMoveHistoryContainer {
  width: 100%;
  height: 80%;

  display: flex;
  flex-direction: row;
  justify-content: center;
}

.chessBoardContainer {
  padding: 0;
  margin: 0;
}

.chessBoardTable {
  border: 2px solid rgb(0, 0, 0, 0.5);
  border-spacing: 0;

  display: inline-block;
  position: relative;
  vertical-align: top;

  user-select: none;
}

.white,
.black {
  width: 10vmin;
  height: 10vmin;
  padding: 0;

  position: relative;
}

.white {
  background-color: white;
}

.black {
  background-color: rgb(1, 166, 172);
  color: white;
}

.chessSquare {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;
}

.chessPiece {
  cursor: pointer;
  width: 100%;
  height: 100%;
  /* -webkit-user-drag: none; */
  user-select: none;
}

.dot {
  height: 3vmin;
  width: 3vmin;
  background-color: rgb(187, 187, 187);
  border-radius: 50%;
  display: inline-block;
}

.chessSquare:hover .dot,
.mouseover {
  background-color: rgb(150, 150, 150);
}

.movable {
  cursor: pointer;
}

.capturable {
  background-color: rgb(187, 187, 187) !important;
}

.capturable:hover {
  background-color: rgb(150, 150, 150) !important;
}

.check {
  background-color: red;
}

.checkmate {
  background-color: rgb(132, 0, 255);
}

.selected {
  background-color: rgb(40, 134, 40);
}

.white.lastMoved {
  background-color: rgb(222, 210, 25);
}

.black.lastMoved {
  background-color: rgb(222, 210, 25, 0.7);
}

.winnerDisplay {
  position: absolute;
  background-color: white;
  height: 40vh;
  width: 60vmin;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.back {
  position: absolute;
  left: 5%;

  font-size: 40px;
  border: 5px solid var(--darkgray);
  border-radius: 5rem;
  padding: 5px 15px 5px 15px;
  cursor: pointer;

  background-color: rgb(150, 150, 150, 0.75);
  color: white;
  font-weight: bold;
  text-decoration: none;

  display: flex;
  align-items: center;
}

.backText {
  margin-left: 10px;
}

.lettering {
  position: absolute;
  bottom: 2px;
  left: 5px;

  font-size: 1vw;
}

.numbering {
  position: absolute;
  top: 2px;
  right: 5px;

  font-size: 1vw;
}

.moveHistory {
  display: inline-block;
  width: 15vw;
  height: 100%;
  overflow-y: auto;
  margin-left: 30px;
  border-radius: 0.2rem;

  background-color: rgb(150, 150, 150, 0.5);

  color: white;
  font-size: 1.5rem;
}

.moveHistoryTitle {
  margin: 0;
  padding: 5px;

  font-size: inherit;
  font-family: inherit;
}

.moveRow {
  display: flex;
  justify-content: left;
  align-items: center;
}

.moves {
  width: 100%;
}

.moveNum {
  padding: 5px 10px;
  display: inline-block;
}

.move {
  padding: 5px 0;
  display: inline-block;
  height: 100%;
  width: 49%;
}
