:root {
    --lightgray: rgb(150, 150, 150);
    --darkgray: rgb(100, 100, 100);
}

html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    text-align: center;
}

.outside {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    position: absolute;
    top: 0;
    bottom: 0;
    /* z-index: -1; */
}

#leftBox {
    background-image: linear-gradient(to left, var(--darkgray), var(--lightgray));
}

#rightBox {
    background-image: linear-gradient(to right, var(--darkgray), var(--lightgray));
}

.mainRow {
    height: 100%;
    display: flex;
    flex-direction: row;
}
